.login_container {
	width: 100%;
	min-height: 80vh;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login_form_container {
	width: 80%;
	height: 70vh;
	display: flex;
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.left h1 {
	font-size: 30px;
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.error_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgb(37 99 235);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.right h1 {
	margin-top: 0;
	color: white;
	font-size: 30px;
	align-self: center;
}

.white_btn,
.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.green_btn {
	background-color: rgb(37 99 235);
	color: white;
	margin: 10px;
}

@media screen and (max-width: 884px) {

	.login_form_container   {
		flex-direction: column;
		width: 100%;
		height: 80vh;
	}
	.left,
	.right {
		border-radius: 10px;
	}
	.left {
		border-bottom-left-radius: 0;
		border-top-right-radius: 10px;
	}
	.right {
		border-top-right-radius: 0;
		border-bottom-left-radius: 10px;
	}
	.input {
		width: 95%;
		flex-direction: column;
	}
	.left h1 {
		font-size: 20px;
	}	

}
